body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}


// breakpoints
$xs-max: 768px;
$md-min: 769px;
$md-max: 1600px;
$lg-min: 1601px;
$lg-medium: 1700px;
$lg-max: 2500px;
$xl-min: 2501px;

// colors
$dark: #454C68;
$dark600: #33384A;
$grey: #9498A6;
$grey2: #BCBEC5;
$lightGrey: #F2F4F8;
$white: #FFFFFF;
$red400: #FF0000;
$red: #FF3232;
$red600: #D00000;
$blue: #3265FF;
$blue600: #1D48CC;
$green: #2B9F1C;
$yellow: #FFB905;

@mixin greyBlock {
  padding: 50px !important;
  background: $lightGrey !important;
  @media (min-width: $md-min) and (max-width: $md-max) {
    padding: 30px !important;
  }
  @media (max-width: $xs-max) {
    padding: 20px !important;
  }
}

.ql-container{

  width: 100%;
  overflow-y: auto;
  max-height: 500px;
 border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
}
@mixin editor{
  display: block;
  margin: 0 auto;
  min-height:200px;
  max-width: 1000px;
  font-family: 'Montserrat', sans-serif !important;
  padding: 50px 50px !important;
  overflow: hidden;
  font-size: 21px !important;
  font-weight: 500;
  color: #000;
  line-height: 1.5 !important;
  box-shadow: 0 40px 40px 0 rgb(0 0 0 / 10%) !important;;
  &:after{
    display: none !important;
  }
  &:before{
    display: none !important;
  }
  @media (max-width: $xs-max) {
    padding-top: 40px;
    padding-bottom: 20px;
    font-size: 16px;
  }
  &.withSlider{
    padding-bottom: 0px;
  }

    p{
      margin: 0 !important;
      margin-bottom: 0px !important;
    }
    a{
      color: $blue;
      text-decoration: underline;
      &:hover{
        text-decoration: none;
      }
    }
    figure{
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      img{
        display: block;
        max-width: 100%;
      }
      figcaption{
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
        color: $grey;
        text-align: left;
        margin: 4px 0px 0px 0px;
        padding: 0;
      }
    }
    blockquote{
      border: none !important;
      &:before{
        display: none;
      }
      margin: 0 !important;
      font-style: italic;
      font-size: 28px;
      font-weight: 500;
      @include greyBlock;
      @media (max-width: $xs-max) {
        font-size: 18px;
      }
    }
    ul, ol{
      @include greyBlock;
      margin: 0;
      list-style-position: inside;
      color: $dark;
    }
    ol{
      li {
        list-style-type: decimal;
      }
      li::marker{
        font-size: 18px !important;
        color: $grey !important;
        @media (max-width: $xs-max) {
          font-size: 16px !important;
        }
      }
    }
    ul{
      li {
        list-style-type: circle !important;
      }
    }
    li{

      padding-left: 0px !important;
      &:before{
        display: none;
      }

    }

}
.ql-card-editable{
  cursor: pointer;
  &:hover{
    box-shadow: 0 40px 40px 0 rgb(0 0 0 / 10%);
  }
  input{
    width: 100% !important;
    min-width: 100%;
  }
}
.ra-rich-text-input .ql-editor{
  @include editor;
}
.ql-editor-preview-container{
  height: 100%;
  min-height: 100%;
}
.ql-editor-preview{
  @include editor;
  .ql-card-editable{
    &:hover{
      box-shadow: none;
    }
  }
}
